/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Gilroy-Bold ☞';
font-style: normal;
font-weight: normal;
src: local('Gilroy-Bold ☞'), url('Gilroy-Bold.woff') format('woff');
}


@font-face {
font-family: 'Gilroy-Heavy ☞';
font-style: normal;
font-weight: normal;
src: local('Gilroy-Heavy ☞'), url('Gilroy-Heavy.woff') format('woff');
}


@font-face {
font-family: 'Gilroy-Light ☞';
font-style: normal;
font-weight: normal;
src: local('Gilroy-Light ☞'), url('Gilroy-Light.woff') format('woff');
}


@font-face {
font-family: 'Gilroy-Medium ☞';
font-style: normal;
font-weight: normal;
src: local('Gilroy-Medium ☞'), url('Gilroy-Medium.woff') format('woff');
}


@font-face {
font-family: 'Gilroy-Regular ☞';
font-style: normal;
font-weight: normal;
src: local('Gilroy-Regular ☞'), url('Gilroy-Regular.woff') format('woff');
}